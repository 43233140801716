@mixin video-icon-dimension($iconSize: 'large') {
  //Icon Sizes
  $small: 'small';
  $medium: 'medium';
  $large: 'large';
  $xLarge: 'xLarge';

  @if $iconSize == 'small' {
    @apply w-10;
    @apply h-10;
  }

  @if $iconSize == 'medium' {
    @apply w-14;
    @apply h-14;
  }

  @if $iconSize == 'large' {
    @apply w-16;
    @apply h-16;
  }

  @if $iconSize == 'xLarge' {
    @apply w-16;
    @apply h-16;
  }
}
