@use '/styles/mixin/videoPlayicon.module.scss' as *;

.drive-thumbnail {
  @apply w-full;
  @apply transition-opacity;
  @apply duration-500;
  @apply cursor-pointer;
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply absolute;
  @apply top-0;
  @apply bottom-0;
  @apply opacity-75;
  &:hover {
    @apply opacity-100;
  }

  &__icon {
    @apply text-gray-300;
  }
  &__icon-small {
    @apply text-gray-300;
    @apply w-10;
    @apply h-10;
    @screen xs {
      @include video-icon-dimension('medium');
    }
    @screen lg {
      @include video-icon-dimension('xLarge');
    }
  }
  &__icon-no-compact {
    @apply text-3xl;
    @screen md {
      @apply h-50px;
      @apply w-50px;
    }
  }
  &__icon-compact {
    @apply text-7xl;
    @screen xs {
      @include video-icon-dimension('medium');
    }
    @screen lg {
      @include video-icon-dimension('xLarge');
    }
  }
}
